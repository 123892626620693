
import { FetchHeaders } from '../../helpers/fetch-headers';

// @ts-ignore
import * as Pristine from 'pristinejs';

// @ts-ignore
declare var grecaptcha;

// @ts-ignore
declare var dataLayer;

import { gsap } from 'gsap';



export class ContactForm {
	
	trigger: HTMLButtonElement;
	
	static pristine: any;
	
	static pristineConfig = {
		classTo: 'field',
		errorClass: 'error',
		successClass: 'success',
		errorTextParent: 'field',
		errorTextTag: 'p',
		errorTextClass: 'error'
	};
	
	contactForm: HTMLFormElement;
	contactFormCta: HTMLButtonElement;
	contactSuccess: HTMLElement;
	contactError: HTMLElement;
	fields: NodeListOf<HTMLElement>;
	
	constructor() {
		
		this.contactForm    = <HTMLFormElement>document.getElementById('contact-form');
		this.contactFormCta = this.contactForm.querySelector('button[type="submit"]');
		this.contactSuccess = document.querySelector('.form-success-message');
		this.contactError   = document.querySelector('#error-container');
		this.fields         = this.contactForm.querySelectorAll('input,select,textarea');
		
		ContactForm.pristine = new Pristine(this.contactForm, ContactForm.pristineConfig);
		

		this.contactFormCta.addEventListener('click', (ev: MouseEvent) => {
			ev.preventDefault();
			
			const valid = ContactForm.pristine.validate();
			
			if (valid) {
				const data: any = {};
				
				this.contactForm.classList.add('submitting');
				
				this.contactFormCta.setAttribute('disabled', '');
				this.fields
				.forEach((el: HTMLInputElement | HTMLSelectElement) => {
					if (el.type == 'radio' || el.type == 'checkbox') {
						// @ts-ignore
						if (el.checked) {
							data[el.getAttribute('name')] = el.value;
						}
					} else {
						data[el.getAttribute('name')] = el.value;
					}
				});
				
				
				const siteKey = this.contactFormCta.dataset.sitekey;
				const action = this.contactForm.getAttribute('action');
				
				grecaptcha.ready(() => {
					grecaptcha
					.execute(siteKey, { action: 'submit' })
					.then((token: string) => {
						data.token = token;
						
						// @ts-ignore
						if (window.datalayer) {
							dataLayer.push({ event: 'form_submit_contact1' });
						}
						
						ContactForm.performAsyncRequest(action, data, (data: any) => {
							
							if (data.result == 'ok') {
								this.afterFormSuccess();
							} else {
								this.contactForm.classList.remove('submitting');

								this.contactFormCta.removeAttribute('disabled');
								this.contactError.innerHTML = '<div class="alert alert-danger"><span>Si &egrave; verificato un errore, il messaggio non &egrave; stato inviato.</span></div>';
							}

						});
					});
				});

			} else {
												
				let errorFields = document.getElementsByClassName('pristine-error');
				let visibileErrorFields = [];
												
				for (var i = 0; i < errorFields.length; i++) {
					var currentElement = errorFields[i];
					var style = window.getComputedStyle(currentElement, null );

					if (style.display != 'none') {
						visibileErrorFields.push(currentElement);
					}
				}

				// visibileErrorFields[0].scrollIntoView({
				// 	behavior: 'smooth',
				// 	block: 'center',
				// 	inline: 'center',
				// });

			}

		});

	}


	public afterFormSuccess() {
		this.contactSuccess.classList.add( 'on_top' );
		this.contactForm.reset();
			
		document.querySelector('#error-container').innerHTML = '';

		// this.contactSuccess.scrollIntoView({
		// 	behavior: 'smooth',
		// 	block: 'center',
		// 	inline: 'center',
		// });

		gsap.to(this.contactSuccess, {
			opacity: 1,
			duration: 1,
		});
				
		// Opacizza form a 0
		gsap.to(this.contactForm, {
			opacity: 0,
			duration: 1,
			onComplete: () => {
				this.reappearForm();
			},
		});

	}


	public reappearForm() {
		gsap.to(this.contactSuccess, {
			opacity: 0,
			duration: 1,
			delay: 1
		});

		gsap.to( this.contactForm, {
			opacity: 1,
			duration: 1,
			delay: 1,
			onComplete: () => {
				this.contactForm.classList.remove('submitting');
				this.contactFormCta.removeAttribute('disabled');
				this.contactSuccess.classList.remove('on_top');
			},
		});
	}

										
	public static performAsyncRequest( url: string, data: any, callback: Function ) {
		fetch(url, {
			headers: FetchHeaders.prepare(),
			method: 'post',
			credentials: 'same-origin',
			body: JSON.stringify(data),
		})
		.then((response) => response.json())
		.then((data) => {

			if (data.result === 'ok') {
				callback(data);
			} else if (data.result === 'ko') {
				ContactForm.showErrorMessage(data.error);
			} else if (data.exception) {
				//ContactForm.showErrorMessage(data.message); //local debug
				ContactForm.showErrorMessage();
			}
			
		})
		.catch((error) => {
			ContactForm.showErrorMessage(error);
		});
	}
		
	
	public static showErrorMessage(error?: string) {
		if (!error.length) {
			error = 'Si &egrave; verificato un errore, il messaggio non &egrave; stato inviato.';
		}
		
		document.querySelector('#error-container').innerHTML = '<div class="alert alert-danger"><span>' + error + '</span></div>';
		document.querySelector('form').classList.remove('submitting');
		document.querySelector('button[type="submit"]').removeAttribute('disabled');
	}
		
}
										
										