
export class FetchHeaders {

	public static prepare() {
		let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

		return {
			'Content-Type':      'application/json',
			'Accept':            'application/json, text-plain, */*',
			'X-Requested-With':  'XMLHttpRequest',
			'X-CSRF-TOKEN':      token
		};
	}

}
