import { ContactForm } from "./components/contact-form/contact-form";




document.addEventListener('DOMContentLoaded', function() {

    let logo =  document.getElementById("logo")
    logo.addEventListener("click", (ev: MouseEvent) => {
        window.scroll({top:0,behavior:'smooth'})
    })

	if ( document.getElementById('contact-form') ) {
		new ContactForm();
	}

	//mailgo();

}, false);

let previousScrollPosition = 0;

window.addEventListener("scroll", function () {
   if (window.pageYOffset > 50) {
          document.body.classList.add("scrolling");
   }
   else {
	      document.body.classList.remove("scrolling");
   }
});


const isScrollingDown = () => {
    let goingDown = false;
    let scrollPosition = window.pageYOffset;
    if (scrollPosition > previousScrollPosition && window.pageYOffset>50) {
        goingDown = true;
    }
    previousScrollPosition = scrollPosition;
    return goingDown;
};
const handleScroll = () => {
    if (isScrollingDown()) {
        document.body.classList.add("scroll-down");
        document.body.classList.remove("scroll-up");
    } else {
        document.body.classList.add("scroll-up");
        document.body.classList.remove("scroll-down");
    }
};

window.addEventListener("scroll", handleScroll);
